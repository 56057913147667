var easings = {
    linear: function(t) {
        return t;
    },
    easeInQuad: function(t) {
        return t * t;
    },
    easeOutQuad: function(t) {
        return t * (2 - t);
    },
    easeInOutQuad: function(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic: function(t) {
        return t * t * t;
    },
    easeOutCubic: function(t) {
        return --t * t * t + 1;
    },
    easeInOutCubic: function(t) {
        return t < 0.5
            ? 4 * t * t * t
            : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart: function(t) {
        return t * t * t * t;
    },
    easeOutQuart: function(t) {
        return 1 - --t * t * t * t;
    },
    easeInOutQuart: function(t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    easeInQuint: function(t) {
        return t * t * t * t * t;
    },
    easeOutQuint: function(t) {
        return 1 + --t * t * t * t * t;
    },
    easeInOutQuint: function(t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    }
};

function scrollIt(destination, duration, easing) {
    duration = duration || 200;
    easing = easing || "linear";

    var start = window.pageYOffset;
    var startTime = performance.now();

    var documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );

    var windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    var destinationOffset =
        typeof destination === "number" ? destination : destination.offsetTop;

    var destinationOffsetToScroll = Math.round(
        documentHeight - destinationOffset < windowHeight
            ? documentHeight - windowHeight
            : destinationOffset
    );

    function scroll() {
        var now = performance.now();
        var time = Math.min(1, (now - startTime) / duration);
        var timeFunction = easings[easing](time);

        window.scroll(
            0,
            Math.ceil(
                timeFunction * (destinationOffsetToScroll - start) + start
            )
        );

        requestAnimationFrame(scroll);
    }

    scroll();
}

var anchors = document.querySelectorAll(".js-anchor");
anchors = Array.prototype.slice.call(anchors, 0);

anchors.forEach(function(anchor, i) {
    anchor.addEventListener("click", function(event) {
        var hash = event.target.hash;

        if (hash && document.querySelector(hash)) {
            event.preventDefault();
            scrollIt(document.querySelector(hash), 300, "easeInOutQuad");
        }
    });
});
