if (window.innerWidth - document.documentElement.clientWidth) {
    document.documentElement.className += " is-showingScrollbar";
}

if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

window.addEventListener("load", function() {
    document.body.classList.add("is-loaded");
});

window.addEventListener("beforeunload", function(ev) {
    document.body.classList.remove("is-loaded");
});

var toggleStateMenu = document.querySelector(".js-toggleStateMenu");
if (toggleStateMenu) {
    toggleStateMenu.addEventListener("click", function() {
        document.body.classList.toggle("is-state--menu");
    });
}

var toggleStateSearch = document.querySelector(".js-toggleStateSearch");
if (toggleStateSearch) {
    toggleStateSearch.addEventListener("click", function(e) {
        e.preventDefault();
        document.body.classList.toggle("is-state--search");
        return false;
    });
}

var toggleNotice = document.querySelector(".Header-noticeClose");
if (toggleNotice) {
    toggleNotice.addEventListener("click", function() {
        var noticeContent = document.querySelector(".Header-noticeContent");
        Cookies.remove("dismissed_site_notice");
        Cookies.set("dismissed_site_notice", noticeContent.innerHTML.trim());

        var notice = document.querySelector(".Header-notice");
        notice.addEventListener("transitionend", function(event) {
            notice.remove();
        });

        document.body.classList.remove("is-notice");
    });
}

var heroSlideshow = document.querySelector(".Hero-slideshowSlides");
if (heroSlideshow) {
    var flkty = new Flickity(heroSlideshow, {
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true
    });

    var slideshowControls = document.querySelector(".Hero-slideshowControls");
    var slideshowSlides = flkty.getCellElements();
    var paginationDots = [];

    slideshowSlides.forEach(function(slide, i) {
        var button = document.createElement("button");
        button.classList.add("Hero-slideshowDot");

        if (i === 0) {
            button.classList.add("is-active");
        }

        button.addEventListener("click", function() {
            flkty.select(i);
        });

        slideshowControls
            .querySelector(".Hero-slideshowDots")
            .appendChild(button);
        paginationDots.push(button);
    });

    slideshowControls
        .querySelector(".Hero-slideshowButton--prev")
        .addEventListener("click", function() {
            flkty.previous(true);
        });

    slideshowControls
        .querySelector(".Hero-slideshowButton--next")
        .addEventListener("click", function() {
            flkty.next(true);
        });

    flkty.on("select", function(i) {
        var activeDot = slideshowControls.querySelector(
            ".Hero-slideshowDot.is-active"
        );
        activeDot.classList.remove("is-active");
        paginationDots[i].classList.add("is-active");
    });
}
