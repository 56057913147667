var contactFormWrapper = document.querySelector(".ContactOverlay-wrapper");

if (contactFormWrapper) {
    contactFormWrapper.addEventListener("click", function(event) {
        if (event.target === contactFormWrapper) {
            contactFormWrapper.classList.remove("is-visible");
        }
    });

    var contactFormLinks = document.querySelectorAll(".js-openContactForm");
    contactFormLinks = Array.prototype.slice.call(contactFormLinks, 0);

    contactFormLinks.forEach(function(link) {
        link.addEventListener("click", function(event) {
            event.preventDefault();
            contactFormWrapper.classList.toggle("is-visible");
        });
    });

    document.addEventListener("keydown", function(event) {
        // Esc
        if (event.keyCode === 27) {
            contactFormWrapper.classList.remove("is-visible");
        }
    });
}

function submitForm(form, options) {
    options.responseMessageClass =
        options.responseMessageClass || "ContactOverlay-responseMessage";
    options.defaultErrorMessage =
        options.defaultErrorMessage ||
        "Något gick fel, mejla direkt till <a href='mailto:info@abytravet.se'>info@abytravet.se</a> så hjälper vi dig!";

    var xhr = new XMLHttpRequest();
    var formData = new FormData(form);

    var disabledElements = form.querySelectorAll(
        "input, textarea, select, button"
    );
    disabledElements = Array.prototype.slice.call(disabledElements, 0);

    disabledElements.forEach(function(element) {
        element.disabled = true;
    });

    form.classList.add("is-loading");

    function setResponseMessage(status, message) {
        form.classList.remove("is-loading");

        var responseMessage = document.querySelector(
            "." + options.responseMessageClass
        );
        if (!responseMessage) {
            var responseMessage = document.createElement("div");
            responseMessage.className = options.responseMessageClass;
            form.appendChild(responseMessage);
        }

        if (status === "success") {
            responseMessage.classList.add("is-success");
        } else {
            disabledElements.forEach(function(element) {
                element.disabled = false;
            });
            responseMessage.classList.add("is-error");
        }

        responseMessage.innerHTML = message;
    }

    xhr.addEventListener("load", function(event) {
        var contentType = event.target.getResponseHeader("content-type");
        var isJSON = contentType.indexOf("application/json") > -1;
        var response = isJSON ? JSON.parse(event.target.response) : {};
        var status = event.target.status >= 200 ? "success" : "error";

        setResponseMessage(status, response.message);
    });

    xhr.addEventListener("error", function() {
        setResponseMessage("error", options.defaultErrorMessage);
    });

    xhr.open(form.method, form.action);
    xhr.send(formData);
}

var contactForm = document.querySelector(".ContactOverlay-form");
if (contactForm) {
    window.SUBMIT_CONTACT_FORM = function() {
        submitForm(contactForm, {
            responseMessageClass: "ContactOverlay-responseMessage"
        });
    };
}

var newsletterForm = document.querySelector(".Footer-newsletter");
if (newsletterForm) {
    newsletterForm.addEventListener("submit", function(event) {
        event.preventDefault();
        submitForm(event.target, {
            responseMessageClass: "Footer-newsletterResponseMessage"
        });
    });
}
